//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

    props: [
        'content'
    ],
    computed: {
        loc() {
            return this.$store.state.location
        },
    }
}
